const areaChartSkeletonData = [
  { x: 1609713737000, y: '1039.22' },
  { x: 1609717337000, y: '1055.35' },
  { x: 1609720937000, y: '1044.97' },
  { x: 1609724537000, y: '1060.22' },
  { x: 1609728137000, y: '1045.66' },
  { x: 1609731737000, y: '1086.00' },
  { x: 1609735337000, y: '1104.53' },
  { x: 1609738937000, y: '1078.25' },
  { x: 1609742537000, y: '1057.62' },
  { x: 1609746137000, y: '1027.17' },
  { x: 1609749737000, y: '979.37' },
  { x: 1609753337000, y: '960.14' },
  { x: 1609756937000, y: '975.50' },
  { x: 1609760537000, y: '979.99' },
  { x: 1609764137000, y: '995.95' },
  { x: 1609767737000, y: '981.91' },
  { x: 1609771337000, y: '942.96' },
  { x: 1609774937000, y: '948.09' },
  { x: 1609778537000, y: '932.33' },
  { x: 1609782137000, y: '898.30' },
  { x: 1609785737000, y: '894.69' },
  { x: 1609789337000, y: '849.44' },
  { x: 1609792937000, y: '859.20' },
  { x: 1609796537000, y: '877.65' },
  { x: 1609800137000, y: '895.65' },
  { x: 1609803737000, y: '885.87' },
  { x: 1609807337000, y: '901.27' },
  { x: 1609810937000, y: '851.37' },
  { x: 1609814537000, y: '874.92' },
  { x: 1609818137000, y: '836.46' },
  { x: 1609821737000, y: '832.17' },
  { x: 1609825337000, y: '794.13' },
  { x: 1609828937000, y: '830.55' },
  { x: 1609832537000, y: '877.57' },
  { x: 1609836137000, y: '919.35' },
  { x: 1609839737000, y: '925.23' },
  { x: 1609843337000, y: '943.55' },
  { x: 1609846937000, y: '949.26' },
  { x: 1609850537000, y: '978.06' },
  { x: 1609854137000, y: '960.55' },
  { x: 1609857737000, y: '950.21' },
  { x: 1609861337000, y: '971.43' },
  { x: 1609864937000, y: '988.58' },
  { x: 1609868537000, y: '998.78' },
  { x: 1609872137000, y: '1046.26' },
  { x: 1609875737000, y: '1052.16' },
  { x: 1609879337000, y: '1083.78' },
  { x: 1609882937000, y: '1107.37' },
  { x: 1609886537000, y: '1154.97' },
  { x: 1609890137000, y: '1184.89' },
  { x: 1609893737000, y: '1214.14' },
  { x: 1609897337000, y: '1167.44' },
  { x: 1609900937000, y: '1206.90' },
  { x: 1609904537000, y: '1204.20' },
  { x: 1609908137000, y: '1161.45' },
  { x: 1609911737000, y: '1135.45' },
  { x: 1609915337000, y: '1106.11' },
  { x: 1609918937000, y: '1129.47' },
  { x: 1609922537000, y: '1138.91' },
  { x: 1609926137000, y: '1155.78' },
  { x: 1609929737000, y: '1185.06' },
  { x: 1609933337000, y: '1221.04' },
  { x: 1609936937000, y: '1229.50' },
  { x: 1609940537000, y: '1259.93' },
  { x: 1609944137000, y: '1250.59' },
  { x: 1609947737000, y: '1248.49' },
  { x: 1609951337000, y: '1267.52' },
  { x: 1609954937000, y: '1259.23' },
  { x: 1609958537000, y: '1308.10' },
  { x: 1609962137000, y: '1299.48' },
  { x: 1609965737000, y: '1290.85' },
  { x: 1609969337000, y: '1245.31' },
  { x: 1609972937000, y: '1256.81' },
  { x: 1609976537000, y: '1285.81' },
  { x: 1609980137000, y: '1284.16' },
  { x: 1609983737000, y: '1281.95' },
  { x: 1609987337000, y: '1293.13' },
  { x: 1609990937000, y: '1291.64' },
  { x: 1609994537000, y: '1338.51' },
  { x: 1609998137000, y: '1297.00' },
  { x: 1610001737000, y: '1304.85' },
  { x: 1610005337000, y: '1303.36' },
  { x: 1610008937000, y: '1345.92' },
  { x: 1610012537000, y: '1353.38' },
  { x: 1610016137000, y: '1395.92' },
  { x: 1610019737000, y: '1436.48' },
  { x: 1610023337000, y: '1397.03' },
  { x: 1610026937000, y: '1406.11' },
  { x: 1610030537000, y: '1385.98' },
  { x: 1610034137000, y: '1379.35' },
  { x: 1610037737000, y: '1390.42' },
  { x: 1610041337000, y: '1377.39' },
  { x: 1610044937000, y: '1351.56' },
  { x: 1610048537000, y: '1365.82' },
  { x: 1610052137000, y: '1367.29' },
  { x: 1610055737000, y: '1361.89' },
  { x: 1610059337000, y: '1345.36' },
  { x: 1610062937000, y: '1333.89' },
  { x: 1610066537000, y: '1320.52' },
  { x: 1610070137000, y: '1348.57' },
  { x: 1610073737000, y: '1305.89' },
  { x: 1610077337000, y: '1312.96' },
  { x: 1610080937000, y: '1342.05' },
  { x: 1610084537000, y: '1382.31' },
  { x: 1610088137000, y: '1397.36' },
  { x: 1610091737000, y: '1408.00' },
  { x: 1610095337000, y: '1427.72' },
  { x: 1610098937000, y: '1435.65' },
  { x: 1610102537000, y: '1447.51' },
  { x: 1610106137000, y: '1442.26' },
  { x: 1610109737000, y: '1473.95' },
  { x: 1610113337000, y: '1475.47' },
  { x: 1610116937000, y: '1485.26' },
  { x: 1610120537000, y: '1494.13' },
  { x: 1610124137000, y: '1501.13' },
  { x: 1610127737000, y: '1551.04' },
  { x: 1610131337000, y: '1582.01' },
  { x: 1610134937000, y: '1579.91' },
  { x: 1610138537000, y: '1550.60' },
  { x: 1610142137000, y: '1560.85' },
  { x: 1610145737000, y: '1539.70' },
  { x: 1610149337000, y: '1585.54' },
  { x: 1610152937000, y: '1538.52' },
  { x: 1610156537000, y: '1506.83' },
  { x: 1610160137000, y: '1470.67' },
  { x: 1610163737000, y: '1477.48' },
  { x: 1610167337000, y: '1478.05' },
  { x: 1610170937000, y: '1502.43' },
  { x: 1610174537000, y: '1456.08' },
  { x: 1610178137000, y: '1487.34' },
  { x: 1610181737000, y: '1485.40' },
  { x: 1610185337000, y: '1437.78' },
  { x: 1610188937000, y: '1443.81' },
  { x: 1610192537000, y: '1476.28' },
  { x: 1610196137000, y: '1469.21' },
  { x: 1610199737000, y: '1469.60' },
  { x: 1610203337000, y: '1475.75' },
  { x: 1610206937000, y: '1483.22' },
  { x: 1610210537000, y: '1477.52' },
  { x: 1610214137000, y: '1454.49' },
  { x: 1610217737000, y: '1410.17' },
  { x: 1610221337000, y: '1436.41' },
  { x: 1610224937000, y: '1401.89' },
  { x: 1610228537000, y: '1367.46' },
  { x: 1610232137000, y: '1325.42' },
  { x: 1610235737000, y: '1354.70' },
  { x: 1610239337000, y: '1307.54' },
  { x: 1610242937000, y: '1297.68' },
  { x: 1610246537000, y: '1275.01' },
  { x: 1610250137000, y: '1240.65' },
  { x: 1610253737000, y: '1211.23' },
  { x: 1610257337000, y: '1215.48' },
  { x: 1610260937000, y: '1256.38' },
  { x: 1610264537000, y: '1212.30' },
  { x: 1610268137000, y: '1179.84' },
  { x: 1610271737000, y: '1208.81' },
  { x: 1610275337000, y: '1233.45' },
  { x: 1610278937000, y: '1189.94' },
  { x: 1610282537000, y: '1229.52' },
  { x: 1610286137000, y: '1263.01' },
  { x: 1610289737000, y: '1287.01' },
  { x: 1610293337000, y: '1268.89' },
  { x: 1610296937000, y: '1245.17' },
  { x: 1610300537000, y: '1226.22' },
  { x: 1610304137000, y: '1236.41' },
  { x: 1610307737000, y: '1221.02' },
  { x: 1610311337000, y: '1230.03' },
  { x: 1610314937000, y: '1207.08' },
  { x: 1610318537000, y: '1210.41' },
  { x: 1610322137000, y: '1234.81' },
  { x: 1610325737000, y: '1251.68' },
  { x: 1610329337000, y: '1292.31' },
  { x: 1610332937000, y: '1301.77' },
  { x: 1610336537000, y: '1278.29' },
  { x: 1610340137000, y: '1234.58' },
  { x: 1610343737000, y: '1247.40' },
  { x: 1610347337000, y: '1215.88' },
  { x: 1610350937000, y: '1265.60' },
  { x: 1610354537000, y: '1227.24' },
  { x: 1610358137000, y: '1244.89' },
  { x: 1610361737000, y: '1274.06' },
  { x: 1610365337000, y: '1321.78' },
  { x: 1610368937000, y: '1283.42' },
  { x: 1610372537000, y: '1314.68' },
  { x: 1610376137000, y: '1309.94' },
  { x: 1610379737000, y: '1290.92' },
  { x: 1610383337000, y: '1251.05' },
  { x: 1610386937000, y: '1240.49' },
  { x: 1610390537000, y: '1209.09' },
  { x: 1610394137000, y: '1241.62' },
  { x: 1610397737000, y: '1207.97' },
  { x: 1610401337000, y: '1251.16' },
  { x: 1610404937000, y: '1287.36' },
  { x: 1610408537000, y: '1257.49' },
  { x: 1610412137000, y: '1259.71' },
  { x: 1610415737000, y: '1226.95' },
  { x: 1610419337000, y: '1237.94' },
  { x: 1610422937000, y: '1221.65' },
  { x: 1610426537000, y: '1251.46' },
  { x: 1610430137000, y: '1282.59' },
  { x: 1610433737000, y: '1316.01' },
  { x: 1610437337000, y: '1322.69' },
  { x: 1610440937000, y: '1301.48' },
  { x: 1610444537000, y: '1252.91' },
  { x: 1610448137000, y: '1226.20' },
  { x: 1610451737000, y: '1192.56' },
  { x: 1610455337000, y: '1191.12' },
  { x: 1610458937000, y: '1238.83' },
  { x: 1610462537000, y: '1207.01' },
  { x: 1610466137000, y: '1245.78' },
  { x: 1610469737000, y: '1253.02' },
  { x: 1610473337000, y: '1287.77' },
  { x: 1610476937000, y: '1324.75' },
  { x: 1610480537000, y: '1360.72' },
  { x: 1610484137000, y: '1350.64' },
  { x: 1610487737000, y: '1337.22' },
  { x: 1610491337000, y: '1376.18' },
  { x: 1610494937000, y: '1379.97' },
  { x: 1610498537000, y: '1375.33' },
  { x: 1610502137000, y: '1376.73' },
  { x: 1610505737000, y: '1378.83' },
  { x: 1610509337000, y: '1381.62' },
  { x: 1610512937000, y: '1420.55' },
  { x: 1610516537000, y: '1429.99' },
  { x: 1610520137000, y: '1396.18' },
  { x: 1610523737000, y: '1402.38' },
  { x: 1610527337000, y: '1354.11' },
  { x: 1610530937000, y: '1388.61' },
  { x: 1610534537000, y: '1338.79' },
  { x: 1610538137000, y: '1372.08' },
  { x: 1610541737000, y: '1365.70' },
  { x: 1610545337000, y: '1374.16' },
  { x: 1610548937000, y: '1349.72' },
  { x: 1610552537000, y: '1366.94' },
  { x: 1610556137000, y: '1385.38' },
  { x: 1610559737000, y: '1355.38' },
  { x: 1610563337000, y: '1321.08' },
  { x: 1610566937000, y: '1316.02' },
  { x: 1610570537000, y: '1313.28' },
  { x: 1610574137000, y: '1350.45' },
  { x: 1610577737000, y: '1395.49' },
  { x: 1610581337000, y: '1348.38' },
  { x: 1610584937000, y: '1386.02' },
  { x: 1610588537000, y: '1352.26' },
  { x: 1610592137000, y: '1324.29' },
  { x: 1610595737000, y: '1347.99' },
  { x: 1610599337000, y: '1383.03' },
  { x: 1610602937000, y: '1341.51' },
  { x: 1610606537000, y: '1363.87' },
  { x: 1610610137000, y: '1328.81' },
  { x: 1610613737000, y: '1344.24' },
  { x: 1610617337000, y: '1378.22' },
  { x: 1610620937000, y: '1329.23' },
  { x: 1610624537000, y: '1377.96' },
  { x: 1610628137000, y: '1418.64' },
  { x: 1610631737000, y: '1443.39' },
  { x: 1610635337000, y: '1492.19' },
  { x: 1610638937000, y: '1470.21' },
  { x: 1610642537000, y: '1486.54' },
  { x: 1610646137000, y: '1487.15' },
  { x: 1610649737000, y: '1505.65' },
  { x: 1610653337000, y: '1523.54' },
  { x: 1610656937000, y: '1549.11' },
  { x: 1610660537000, y: '1584.94' },
  { x: 1610664137000, y: '1595.87' },
  { x: 1610667737000, y: '1619.33' },
  { x: 1610671337000, y: '1618.49' },
  { x: 1610674937000, y: '1616.48' },
  { x: 1610678537000, y: '1602.93' },
  { x: 1610682137000, y: '1620.65' },
  { x: 1610685737000, y: '1599.73' },
  { x: 1610689337000, y: '1572.51' },
  { x: 1610692937000, y: '1600.24' },
  { x: 1610696537000, y: '1586.71' },
  { x: 1610700137000, y: '1596.10' },
  { x: 1610703737000, y: '1622.81' },
  { x: 1610707337000, y: '1660.13' },
  { x: 1610710937000, y: '1643.61' },
  { x: 1610714537000, y: '1611.10' },
  { x: 1610718137000, y: '1637.87' },
  { x: 1610721737000, y: '1667.79' },
  { x: 1610725337000, y: '1637.64' },
  { x: 1610728937000, y: '1620.56' },
  { x: 1610732537000, y: '1656.36' },
  { x: 1610736137000, y: '1637.71' },
  { x: 1610739737000, y: '1670.33' },
  { x: 1610743337000, y: '1631.98' },
  { x: 1610746937000, y: '1584.29' },
  { x: 1610750537000, y: '1595.56' },
  { x: 1610754137000, y: '1553.65' },
  { x: 1610757737000, y: '1537.89' },
  { x: 1610761337000, y: '1496.04' },
  { x: 1610764937000, y: '1493.10' },
  { x: 1610768537000, y: '1479.75' },
  { x: 1610772137000, y: '1492.01' },
  { x: 1610775737000, y: '1504.27' },
  { x: 1610779337000, y: '1498.86' },
  { x: 1610782937000, y: '1459.27' },
  { x: 1610786537000, y: '1464.42' },
  { x: 1610790137000, y: '1475.88' },
];

export default areaChartSkeletonData;
