const barChartSkeletonData = {
  '1710160043727': 12,
  '1710160044727': 84,
  '1710160045727': 90,
  '1710160046727': 20,
  '1710160047727': 27,
  '1710160048727': 95,
  '1710160049727': 32,
  '1710160050727': 97,
  '1710160051727': 28,
  '1710160052727': 89,
  '1710160053727': 18,
  '1710160054727': 22,
  '1710160055727': 1,
  '1710160056727': 73,
  '1710160057727': 79,
  '1710160058727': 48,
  '1710160059727': 77,
  '1710160060727': 70,
  '1710160061727': 32,
  '1710160062727': 49,
  '1710160063727': 27,
  '1710160064727': 95,
  '1710160065727': 26,
  '1710160066727': 51,
  '1710160067727': 79,
  '1710160068727': 45,
  '1710160069727': 58,
  '1710160070727': 59,
  '1710160071727': 52,
  '1710160072727': 24,
  '1710160073727': 34,
  '1710160074727': 60,
  '1710160075727': 53,
  '1710160076727': 32,
  '1710160077727': 15,
  '1710160078727': 15,
  '1710160079727': 80,
  '1710160080727': 49,
  '1710160081727': 71,
  '1710160082727': 9,
  '1710160083727': 64,
  '1710160084727': 68,
  '1710160085727': 89,
  '1710160086727': 80,
  '1710160087727': 12,
  '1710160088727': 46,
  '1710160089727': 37,
  '1710160090727': 66,
  '1710160091727': 22,
  '1710160092727': 78,
  '1710160093727': 49,
  '1710160094727': 45,
  '1710160095727': 67,
  '1710160096727': 7,
  '1710160097727': 34,
  '1710160098727': 6,
  '1710160099727': 8,
  '1710160100727': 6,
  '1710160101727': 9,
  '1710160102727': 7,
  '1710160103727': 88,
  '1710160104727': 71,
  '1710160105727': 52,
  '1710160106727': 12,
  '1710160107727': 93,
  '1710160108727': 72,
  '1710160109727': 50,
  '1710160110727': 80,
  '1710160111727': 14,
  '1710160112727': 99,
  '1710160113727': 34,
  '1710160114727': 4,
  '1710160115727': 60,
  '1710160116727': 38,
  '1710160117727': 100,
  '1710160118727': 30,
  '1710160119727': 6,
  '1710160120727': 28,
  '1710160121727': 49,
  '1710160122727': 81,
  '1710160123727': 33,
  '1710160124727': 86,
  '1710160125727': 66,
  '1710160126727': 34,
  '1710160127727': 92,
  '1710160128727': 26,
  '1710160129727': 98,
  '1710160130727': 43,
  '1710160131727': 42,
  '1710160132727': 99,
  '1710160133727': 47,
  '1710160134727': 68,
  '1710160135727': 74,
  '1710160136727': 51,
  '1710160137727': 67,
  '1710160138727': 100,
  '1710160139727': 15,
  '1710160140727': 1,
  '1710160141727': 88,
  '1710160142727': 28,
};
export default barChartSkeletonData;
